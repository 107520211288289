import React, { useState } from "react";
import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { Modal, Form, Button, Image } from 'react-bootstrap';

const FormWaitingList = ({ onSubmit }) => {
    return (
        <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="73" />
            <input type="hidden" name="f" value="73" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <Form.Group className="mb-3">
                <Form.Label>Nama*</Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Masukan Nama Kamu" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email*</Form.Label>
                <Form.Control type="email" name="email" placeholder="Masukan Email Aktif Kamu" required />
            </Form.Group>
            <div className="d-flex justify-content-center">
                <Button className="btn btn-danger man-mastery" type="submit">
                    Join Now
                </Button>
            </div>
        </Form>
    );
};

export default function Waitinglist() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onFormWaitingListSubmit = (e) => {
        e.preventDefault();
        handleClose();
    };
    return (
        <>
            <div className="container-fluid bg-full-primary">
                <div className="row __wrape-align-items-center">
                    <div className="col-lg-12">
                        {/* logo man mastery */}
                        <div className="__wrappe-logo-primary">
                            <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/man-mastery/logo/logo-no-headline.png" alt="logo man mastery" />
                        </div>
                        {/* end logo man mastery */}
                        <div className="text-center __wrappe-headline">
                            <p className="text-white">
                                Agar Pria Senang Dan Sukarela Ingin
                                Mengerti Dan Bahagiakan Kamu
                            </p>
                        </div>
                        <div className="text-center __description-waitinglist">
                            <p className="text-white">
                                Pendaftaran Online Training Man Mastery akan segera dibuka!<br />
                                Yuk join waiting list-nya sekarang<br />
                                Klik button dibawah ini untuk dapatkan penawaran spesialnya!
                            </p>
                        </div>
                        <div className="d-flex justify-content-center __my-margin-50">
                            <Link to="#" role="button" className="btn btn-danger man-mastery" onClick={handleShow}>
                                Join Waiting List
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <Modal
                show={show} onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Joint Waiting List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormWaitingList onSubmit={onFormWaitingListSubmit} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

