import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import WaitingList from '../components/elements/waitinglist';

class Index extends React.Component {
  render() {
    return (
      <>
        <Layout pageInfo={{ pageName: "index" }}>
          <SEO title="Waiting List" keywords={[`Man Mastery`, `Man`, `Mastery`]} />

          {/* All Section */}
          <WaitingList />
          {/* End All Section */}

        </Layout>
      </>
    )
  }
}
export default Index;

